<template>
  <div class="categorie">
    <div class="page-header">
      <h1>{{ $t('Brands / Restaurants') }} > {{ $t('Brands') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <BrandsForm @action="addBrands" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BrandsForm from '@/components/brand/BrandForm.vue';
import Common from '@/lib/common';
import Brand from '@/lib/brand';

export default {
  name: 'AddBrands',
  components: {
    BrandsForm,
  },
  methods:{
    async addBrands(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const categorie = await Brand.createBrand(this.apiUrl, form, loginInfo);
        this.$router.push('/brands');
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.submit-wrapper{
  text-align: right;
}
</style>
