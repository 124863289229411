<template>
  <div class="form-content">
    <el-form ref="form" :model="form">
      <el-form-item :label="$t('Brand Name')" style="max-width: 80%">
        <el-input v-model="form.brandName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Brand Logo')" style="max-width: 80%">
        <el-upload
        :action="uploadUrl"
        :file-list="tmpFile"
        :on-preview="onPreview"
        :on-success="onUploaded"
        :on-remove="onRemove"
        :limit="1"
        list-type="picture-card">
        <i class="el-icon-plus"></i>
      </el-upload>
      </el-form-item>
    </el-form>
    <div class="submit-wrapper">
      <el-button class="submit-button" type="primary" @click="submit">{{ $t('Submit') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'BrandForm',
  mounted(){
    this.uploadUrl = `${this.apiUrl}/brand/file`;
  },
  methods:{
    submit(){
      this.$emit('action', this.form);
    },
    onPreview(file){
      window.open(file.url, "_blank");
    },
    onRemove(file){
      this.form.brandLogo = '';
    },
    async onUploaded(response, file, fileList){
      const fileUrl = response['image_url'];
      this.form.brandLogo = fileUrl;
    },
  },
  data(){
    const form = {
      brandLogo: '',
      brandName: '',
    };
    return {
      tmpFile: [],
      uploadUrl: '',
      form,
    }
  },
  watch: {
    'currentBrand': function(){
      if(this.currentBrand.logo){
        const imageFileName = this.currentBrand.logo.substring(this.currentBrand.logo.lastIndexOf('/')+1);
        const imageFile = {
          name: imageFileName,
          url: this.currentBrand.logo,
        };
        this.tmpFile.push(imageFile);
      }
      this.form.brandLogo = this.currentBrand.logo;
      this.form.brandName = this.currentBrand.name;
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    currentBrand: state => state.brand.currentBrand,
  }),
}
</script>

<style lang="scss">
.form-content{
  padding-left: 20px;
  .el-form-item__label{
    float: none;
  }
  .update-button{
    .el-form-item__content{
      text-align: right;
    }
  }
  .submit-wrapper{
    text-align: right;
  }
}
</style>
